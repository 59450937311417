<template>
  <carousel-3d
    class="carousel"
    :autoplay="true"
    :autoplay-timeout="5000"
    :display="7"
    :width="windowWidth > 600 ? 340 : 180"
    :height="windowWidth > 600 ? 440 : 300"
    :inverseScaling="400"
    :animationSpeed="600"
    :space="windowWidth > 600 ? 340 : 140"
    :perspective="30"
  >
    <slide class="slide" v-for="(slide, i) in slides" :index="i" v-bind:key="i">
      <template slot-scope="{ isCurrent }">
        <a v-if="isCurrent" :href="getHref(slide)">
          <img
            class="slide-img"
            :src="
              windowWidth > 600
                ? require('../../assets/images/carousel/' + slide + '.jpg')
                : require('../../assets/images/carousel_mobile/' +
                    slide +
                    '.jpg')
            "
            alt="Slide image of model or chair"
          />
        </a>
        <img
          v-else
          class="slide-img"
          :src="
            windowWidth > 600
              ? require('../../assets/images/carousel/' + slide + '.jpg')
              : require('../../assets/images/carousel_mobile/' + slide + '.jpg')
          "
          alt="Slide image of model or chair"
        />
      </template>
    </slide>
  </carousel-3d>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "ImageCarousel",
  components: { Carousel3d, Slide },
  created() {
    // Shuffle carousel slides on page load
    this.slides = this.shuffle(this.slides);
  },
  data() {
    return {
      slides: [
        "10001008",
        "10001017",
        "10001033",
        "10001041",
        "10001052",
        "10001053",
        "10001057",
        "10001063",
        "10001084",
        "10001106",
        "10001127",
        "20032002",
        "20032026",
        "20032027",
        "20032037",
        "20032041",
        "20032056",
        "20032057",
        "20032078",
        "20032079",
        "20032082",
        "20110003",
      ],
      // windowWidth is used to make the carousel responsive
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getHref(filename) {
      // Inventory numbers that start with 1 are models
      // Inventory numbers that start with 2 are chairs
      if (filename.startsWith("1")) {
        return "models/" + filename;
      }
      return "chairs/" + filename;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }
      return array;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/sass/variables";
.carousel {
  margin: 0;

  .slide {
    border-radius: 8px;
    border-color: $info;

    .slide-img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
