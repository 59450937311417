<template>
  <div id="app">
    <NavBar />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import NavBar from "@/components/layout/NavBar";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  components: { NavBar },
  created() {
    AOS.init({
      duration: 1000, // values from 0 to 3000, with step 50ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  },
};
</script>
