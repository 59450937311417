


























import { News } from "@/types";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "NewsCard",
  props: {
    news: {
      type: Object as PropType<News>,
      required: true,
    },
  },
  methods: {
    parseIsoDatetime(dtstr: string) {
      return dtstr.split("T")[0];
    },
  },
});
