














































import Vue from "vue";
import { News } from "@/types";
import NewsCard from "@/components/news/NewsCard.vue";
import Footer from "@/components/layout/Footer.vue";
import SearchBar from "@/components/general/SearchBar.vue";
import ImageCarousel from "@/components/general/ImageCarousel.vue";

export default Vue.extend({
  name: "Home",
  components: { ImageCarousel, SearchBar, NewsCard, Footer },
  data() {
    return {
      news: [] as News[],
    };
  },
  created: function () {
    this.news = this.$helpers.loadNews();
  },
});
