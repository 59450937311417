import { BasicID, Chair, Model, News } from "@/types";
import axios from "axios";
import { NEWS_URL } from "@/config";
import Parser from "rss-parser";

export default {
  getDesigners(designers: BasicID[]): string {
    if (
      designers.length === 0 ||
      (designers.length === 1 && designers[0].name === "")
    ) {
      return "Anonymous";
    }
    let designerText = "";
    for (const designer of designers) {
      designerText += designer["name"] + ", ";
    }
    designerText = designerText.slice(0, -2);
    return designerText;
  },
  isImageAvailable(item: Model | Chair): {
    available: boolean;
    filename: string;
  } {
    if ("display_thumbnail" in item) {
      const available = !!item.display_thumbnail;
      return {
        available: available,
        filename: available ? item.display_thumbnail : "",
      };
    }
    const available =
      item.architecturalmodelpictures &&
      item.architecturalmodelpictures.length > 0;
    return {
      available: available,
      filename: available
        ? item.architecturalmodelpictures[0].display_thumbnail
        : "",
    };
  },
  getImageSrc(item: Model | Chair): string {
    const isAvailable = this.isImageAvailable(item);
    if (isAvailable.available) {
      return isAvailable.filename;
    }
    return require("./assets/images/no_image_available.svg");
  },
  loadNews(): News[] {
    const news: News[] = [];
    axios
      .get(NEWS_URL)
      .then(async (response) => {
        if (response.status === 200) {
          // Parse news RSS feed to JSON
          const parser: Parser = new Parser();
          const feed = await parser.parseString(response.data);
          console.log(feed);
          feed.items.forEach((item) => {
            news.push({
              title: item.title || "",
              content: item.content || "",
              link: item.link || "",
              pubDate: item.isoDate || "",
              image: item.enclosure?.url || "",
            });
          });
        }
      })
      .catch(() => {
        // console.log(error);
      });
    return news;
  },
  removeTags(str: string): string {
    if (str) {
      return str.replace(/<[^>]*>?/gm, "").replace(/&nbsp;/g, " ");
    }
    return "";
  },
  formatDate(date: string): string {
    if (!date) {
      return "";
    }
    const temp = date.split("-");
    if (temp.length !== 3) {
      return date;
    }
    return `${temp[2]}/${temp[1]}/${temp[0]}`;
  },
};
