import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import helpers from "./helpers";

import "./assets/sass/main.scss";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.filter("capitalize", function (value: string) {
  if (!value) {
    return "";
  }
  return value.toString().charAt(0).toUpperCase() + value.slice(1);
});

Vue.prototype.$helpers = helpers;

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
