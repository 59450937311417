var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('carousel-3d',{staticClass:"carousel",attrs:{"autoplay":true,"autoplay-timeout":5000,"display":7,"width":_vm.windowWidth > 600 ? 340 : 180,"height":_vm.windowWidth > 600 ? 440 : 300,"inverseScaling":400,"animationSpeed":600,"space":_vm.windowWidth > 600 ? 340 : 140,"perspective":30}},_vm._l((_vm.slides),function(slide,i){return _c('slide',{key:i,staticClass:"slide",attrs:{"index":i},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isCurrent = ref.isCurrent;
return [(isCurrent)?_c('a',{attrs:{"href":_vm.getHref(slide)}},[_c('img',{staticClass:"slide-img",attrs:{"src":_vm.windowWidth > 600
              ? require('../../assets/images/carousel/' + slide + '.jpg')
              : require('../../assets/images/carousel_mobile/' +
                  slide +
                  '.jpg'),"alt":"Slide image of model or chair"}})]):_c('img',{staticClass:"slide-img",attrs:{"src":_vm.windowWidth > 600
            ? require('../../assets/images/carousel/' + slide + '.jpg')
            : require('../../assets/images/carousel_mobile/' + slide + '.jpg'),"alt":"Slide image of model or chair"}})]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }