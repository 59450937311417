import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/models",
    name: "Models",

    component: () => import("../views/Models.vue"),
  },
  {
    path: "/models/:inventoryNumber",
    name: "Model Details",

    component: () => import("../views/ModelDetail.vue"),
  },
  {
    path: "/chairs",
    name: "Chairs",
    component: () => import("../views/Chairs.vue"),
  },
  {
    path: "/chairs/:inventoryNumber",
    name: "Chair Details",

    component: () => import("../views/ChairDetail.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/NewsView.vue"),
  },
  {
    path: "/about",
    name: "About",

    component: () => import("../views/About.vue"),
  },
  {
    name: "Page not found",
    path: "*",
    component: () => import("@/views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
