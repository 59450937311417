<template>
  <div class="searchbar">
    <b-input-group>
      <b-input-group-prepend>
        <b-dropdown :text="type" variant="info">
          <b-dropdown-item
            v-for="option in options"
            :key="option"
            @click="type = option"
            >{{ option }}</b-dropdown-item
          >
        </b-dropdown>
      </b-input-group-prepend>
      <b-form-input
        v-model="searchQuery"
        placeholder="Search..."
        class="search-input"
        @keyup.enter="search"
      ></b-form-input>
      <b-input-group-append>
        <b-button @click="search()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path
              d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
            />
          </svg>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      type: "Chairs",
      options: ["Chairs", "Models"],
      searchQuery: "",
    };
  },
  methods: {
    search() {
      const path = this.type == "Chairs" ? "chairs" : "models";
      this.$router.push({ path: path, query: { search: this.searchQuery } });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/sass/variables";

.searchbar {
  border-radius: 0.32em;
  border: 1px solid $secondary;
}

.search-input:focus,
.search-input:active {
  outline: 1px solid $primary;
  box-shadow: none;
}
</style>
